





































import { Component, Vue } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import { GroupService } from '@/services/group-service';
import { DialogService } from '@/services/common/dialog.service';
import { divisionMap } from './index.config';
import { DivisionOrg, TYPESTATU } from './index.interface';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { UserGroupService } from '@/services/user-group-service';
import { uuid } from '@/services/utils';
import UserGroupDetail from '@/views/userGroup/UserGroupDetail.vue';
import ChannelRuleEdit from '@/views/channel/permissions/dialogs/ChannelRuleEdit.vue';
import ModifyUserGroup from '@/components/UserGroup/ModifyUserGroup.vue';
import UploadUserGroup from '@/views/userGroup/dialog/UploadUserGroup.vue';
import UserGroupApplicationTagGroup from '@/components/UserGroup/UserGroupApplicationTagGroup.vue';
import { cloneDeep } from 'lodash';

@Component({ components: { UserGroupDetail, UserGroupApplicationTagGroup } })
export default class Permissions extends Vue {
  public appName: any = '';
  private loading: boolean = false;
  public isPharma: boolean = false;
  public isOnco: boolean = false;
  public isHrCore: boolean = false; // 添加HR Core
  public isNonFf: boolean = false; // 添加Non Ff
  private searchText: string = '';
  private allGroups: any[] = [];
  private userVoList: any[] = [];

  private activeUserGroup: any[] = [];
  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(GroupService) private groupService!: GroupService;

  @Inject(DialogService) private dialog!:DialogService;

  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  public activeNames: any[] = [];
  public filterNames: any[] = ['1', '2', '3']; // 添加HR core
  public permissionsList: any[] = [];
  public isShow: boolean = true;
  public typeStatus: Dict<any> = TYPESTATU;
  private applicationId: any;
  private ruleOptions: any[] = [];
  private politOptions: any[] = [];
  private hrCoreRuleOptions: any[] = []; // HR Core Rules
  private ruleValue: any[] = [];
  private politValue: any[] = [];
  private hrCoreRuleValue: any[] = []; // 选中的hr core rule
  private totalUsers: any = 0;
  private totalGroups: any = 0;
  private totalList: any[] = [];
  public tagbarSpan: any = 21;


  public async created(): Promise<void> {
    this.appName = this.$route.query.name;
    let controlTypes: any = this.$route.query.types; // 访问权限范围
    let userScopes: any = this.$route.query.userScopes; // 用户范围
    this.applicationId = this.$route.query.id;
    if (
      controlTypes.includes(localStorage.getItem("setup")) &&
      userScopes.includes(TYPESTATU.PHARMA)
    ) {
      this.isPharma = true;
    }
    // 判断是否含有HR CORE
    if (
      controlTypes.includes('HR Core Org') &&
      userScopes.includes(TYPESTATU.HRCORE)
    ) {
      this.isHrCore = true;
    }
    if (
      controlTypes.includes('NON FF') &&
      userScopes.includes(TYPESTATU.NONFF)
    ) {
      this.isNonFf = true;
    }
    this.initAllUserGroups();
  }

  public async modifyUserGroupConfirm(): Promise<void> {
    this.loading = true;
    try {
      // await this.getUserGroupsByApplication(this.$route.query.id);
    } finally {
      this.loading = false;
    }
  }

  public async initAllUserGroups(): Promise<void> {
    this.loading = true;
    try {
      await this.getUserGroupsByApplication(this.$route.query.id);
      await this.getUserGroupsByCheck(this.$route.query.id)    
    } finally {
      this.loading = false;
    }
  }

  public mounted(): void {
    setTimeout(() => {
      const targetBreadcrumb = document.querySelector(
        '#breadcrumb_items_appPermission'
      ) as HTMLElement;
      const targetTitleDom = targetBreadcrumb.querySelector(
        'span.el-breadcrumb__inner.is-link'
      ) as HTMLElement;
      targetTitleDom.innerText = this.$route.query.name + '权限控制';
    });
  }

  private changeActiveUserGroup(val: any) {
    let newVal = cloneDeep(val);
    newVal.refreshTime = Date.now(); 
    let indexItem = JSON.stringify(this.activeUserGroup).indexOf(JSON.stringify(newVal.id));
    if(indexItem === -1){ //不存在添加
       this.activeUserGroup.push(newVal); 
    }else{//存在删除
      let index = this.activeUserGroup.findIndex(function(item) {
         return item.id === newVal.id;
      });
      this.activeUserGroup.splice(index,1);
    }
  }

  private async searchFilteredRules(val: any) {
    this.loading = true;
    if (this.searchText !== '') {
      let res = await this.groupService.searchUserGroupsByApplicationKeyword({
        keyword: this.searchText ? this.searchText : '',
        appId: this.$route.query.id
      })
      this.allGroups = res.userGroupVOList;
      this.userVoList = res.userVoList; 
      this.getUserGroupsByCheck(this.applicationId) 
    }else{
      this.initAllUserGroups();
    }
    this.loading = false;

  }

  private clearSearch(val: any) {
    this.initAllUserGroups();
  }

  public onCancel(): void {
    this.$router.go(-1);
  }

  // 已选中的
  private async getUserGroupsByApplication(id: any): Promise<void> {
    this.applicationId = id;
    let res = await this.groupService.getUserGroupsByApplication({
      applicationId: id
    });
    this.totalUsers = res.totalOfUsers === null ? this.totalUsers : res.totalOfUsers;
    this.totalGroups = res.userGroups === null ? this.totalGroups : res.userGroups.length;
    this.allGroups = res.userGroups;
   }
   //保存
   private async saves(){  
    let activeArr:any = []
    this.activeUserGroup.forEach((group: any) => {    
      activeArr.push(group.id)
    });
    let param = {
      applicationId: this.applicationId,
      groupIds:activeArr
    }
    await this.groupService.saveOrUpdate(param);
    this.$message({
        message: '保存成功',
        type: 'success'
    });
   }

   private async getUserGroupsByCheck(id: any): Promise<void> {
    this.applicationId = id;
    let res = await this.groupService.getUserGroupsByCheck({
      applicationId: id
    });
    if(res.userGroups!= null){
      this.activeUserGroup = res.userGroups;
    }else{
      this.activeUserGroup = []
      this.userVoList = []
    }
   
   }
   
   
  public async refreshUserGroupList(value:any){
    await this.initAllUserGroups();
  }

}
