


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { UserGroupService } from '@/services/user-group-service';
import ModifyUserGroup from '@/components/UserGroup/ModifyUserGroup.vue';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { DialogService } from '@/services/common/dialog.service';
import { uuid } from '@/services/utils';
import { cloneDeep, isArray } from 'lodash';

@Component
export default class UserGroupApplicationTagGroup extends Vue {
  private allAuthorizedUserGroups: any[] = [];

  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(DialogService) private dialogService!: DialogService;
  @Inject(I18nService) private i18nSvc!: I18nService;

  @Prop({ required: true })
  private allGroups: any;

  @Prop({ required: true })
  private activeUserGroup: any;

  @Watch('allGroups')
  private async handleAllGroupsChange(value: any) {
    this.allAuthorizedUserGroups = await this.userGroupService.getAllUserGroups();
    this.allGroups = value;

  }
  
  public checkValue: any[] = []; //存储选中的值数组多选

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  get isFoldTag(){
    return this.activeUserGroup.length === 1
  }

  public async created(): Promise<any> {
    this.allAuthorizedUserGroups = await this.userGroupService.getAllUserGroups();
  }

  public isActiveUserGroup(id: string): Boolean {
    return !!this.activeUserGroup.find((item: any) => item.id == id);
  }

  public hasPermission(item: any): Boolean { 
    return this.allAuthorizedUserGroups.findIndex((userGroup: any) => item.id == userGroup.id) !== -1
  }

  private clickTag(val: any) {
    console.log('click', val);
    const { id, name, type } = val;  
    this.allGroups.forEach((group: any) => {    
      if (group.id == id) {     
        this.$emit('changeActiveUserGroup', group);
      }
    });
  }

  public clickMenu(event: any, item: any): void {
    event.preventDefault();
    event.stopPropagation();
    item.popoverVisible = true;
  }

  
}
